import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { getSiteUrl } from 'helpers/routingHelper';

type PaymentDoneProps = {
  location: Location;
};

const PaymentDone: React.FC<PaymentDoneProps> = ({ location }) => {
  const params = parse(location.search);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'done',
        payload: {
          transactionId: params.nbx_trans_id,
        },
      },
      getSiteUrl() || ''
    );
  }, [params]);

  return null;
};

export default PaymentDone;
